import React, {
  FC,
  memo,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import {
  login as loginService,
  activateAccount as activateAccountService,
  me,
} from 'services/auth';
import { getEnv } from 'services/env';
import { http } from 'services/http';
import {
  API_KEY_PROVIDER_COGNIFLOW,
  ActivationData,
  ActivationResponse,
  AuthenticationData,
  User,
} from 'types';

type AuthContextType = {
  login(username: string, password: string, cb: any): Promise<void>;
  logout(): void;
  isAuthenticated: boolean | null;
  activateAccount(data: ActivationData): Promise<ActivationResponse>;
  user: User | null;
  token: string;
  userApiKey: string;
  setUserProp(prop: Partial<User>): void;
  fetchUser(): void;
  authenticateUser(data: AuthenticationData): void;
  isGuest: boolean;
};

export const AuthenticationContext = createContext<Partial<AuthContextType>>(
  {}
);

export const useAuthenticationContext = () => {
  return useContext(AuthenticationContext);
};

export const AuthenticationProvider: FC<{}> = memo(({ children }) => {
  const [token, setToken] = useState();
  const [isAuthenticated, setAuthenticated] = useState<boolean | null>(null);
  const [user, setUser] = useState<User | null>(null);

  const loginSuccessfully = useCallback((token) => {
    setAuthenticated(true);
    http.setToken(token);
    setToken(token);
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      loginSuccessfully(accessToken);
    } else {
      setAuthenticated(false);
    }
  }, [loginSuccessfully]);

  const fetchUser = useCallback(() => {
    me()
      .then((userData) => {
        setUser(userData);
        if (getEnv('NODE_ENV') === 'production') {
          (window as any).FS.identify(userData.id, {
            email: userData.email,
          });
        }
      })
      .catch((err) => {
        setAuthenticated(false);
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchUser();
    }
  }, [isAuthenticated, fetchUser]);

  const authenticateUser = useCallback(
    ({ email: username, accessToken }) => {
      if (getEnv('NODE_ENV') === 'production') {
        (window as any).$crisp?.push(['set', 'user:email', username]);
      }

      http.setToken(accessToken);

      localStorage.setItem('access_token', accessToken);
      loginSuccessfully(accessToken);
    },
    [loginSuccessfully]
  );

  const login = useCallback(
    (username: string, password: string, cb: any) =>
      loginService({ username, password }).then(
        ({ access_token: accessToken }) => {
          authenticateUser({ email: username, accessToken });

          (window as any).lintrk?.('track', { conversion_id: 14509892 });
          cb?.(accessToken);
        }
      ),
    [authenticateUser]
  );

  const logout = useCallback(() => {
    localStorage.removeItem('access_token');
    setAuthenticated(false);
  }, []);

  const activateAccount = useCallback(
    (data: any) => activateAccountService(data),
    []
  );

  const userApiKey = useMemo(
    () =>
      user?.api_keys.find(
        (apiKey) => apiKey.provider === API_KEY_PROVIDER_COGNIFLOW
      )?.key || '',
    [user]
  );

  const setUserProp = useCallback((prop) => {
    setUser((usr) => {
      if (!usr) return usr;
      return {
        ...usr,
        ...prop,
      };
    });
  }, []);

  const isGuest = useMemo(() => user?.is_company_commercial_plan, [user]);

  return (
    <AuthenticationContext.Provider
      value={{
        login,
        logout,
        isAuthenticated,
        activateAccount,
        user,
        token,
        userApiKey,
        setUserProp,
        fetchUser,
        authenticateUser,
        isGuest,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
});
